<template>
  <div class="lazy-image" :style="pictureStyle">
    <picture ref="lazyImage">
      <source :srcSet="responsiveImages.srcSet" type="image/webp" />
      <img
        :src="responsiveImages.src"
        :srcSet="responsiveImages.srcSet"
        :alt="'An image taken at the ' + sentenceCase(pictureDetails)"
        :photoContext="pictureDetails"
        @load="removeBlur" />
    </picture>
  </div>
</template>

<script>
import lozad from 'lozad'
import { gsap, CSSRulePlugin } from 'gsap/all'

export default {
  name: 'LazyImage',
  props: {
    alt: {
      type: String,
      default: '',
    },
    pictureDetails: {
      type: String,
      default: '',
      required: true,
    },
    responsiveImages: {
      type: Object,
      required: true,
      default: () => ({
        src: '',
        srcSet: '',
      }),
    },
    imagePositionX: {
      required: true,
      type: Number,
      default: 0,
    },
    imagePositionY: {
      required: true,
      type: Number,
      default: 0,
    },
    backgroundImageTint: {
      required: true,
      type: String,
      default: 'rgba(0,0,0,0.1)',
    },
  },
  data() {
    return {
      pictureStyle: {
        // background:
        //   'url(' +
        //   this.responsiveImages.placeholder +
        //   ') ' +
        //   this.imagePositionX +
        //   '%' +
        //   this.imagePositionY +
        //   '% / cover',
        '--backgroundImageTint': this.backgroundImageTint,
        '--x': this.imagePositionX + '%',
        '--y': this.imagePositionY + '%',
      },
      loading: true,
    }
  },
  computed: {
    cssProps() {
      return {
        '--backgroundImageTint': this.backgroundImageTint,
      }
    },
  },
  mounted() {
    gsap.registerPlugin(CSSRulePlugin)
    this.$el.addEventListener('load', this.setLoadingState)
    const observer = lozad(this.$el)
    observer.observe()
  },
  unmounted() {
    this.$el.removeEventListener('load', this.setLoadingState)
  },
  methods: {
    sentenceCase(str) {
      if (str === null || str === '') return false
      else str = str.toString()

      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    setLoadingState() {
      this.loading = false
    },
    removeBlur() {
      if (this.$refs.lazyImage) {
        this.$refs.lazyImage.classList.add('is-loaded')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.lazy-image {
  display: none;
  transform: scale(1.02) translateY(1.02);
}
.lazy-image::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--backgroundImageTint);
}

.lazy-image picture {
  width: 100%;
  height: 100vh;
  position: absolute;
  filter: blur(6px);
}

.lazy-image picture.is-loaded {
  filter: none; /* remove the blur on fullres image */
  transition: filter 0.8s;
}

.lazy-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: var(--x) var(--y);
}

.lazy-image__wrapper {
  position: relative;
  z-index: 200;
}

.lazy-image > picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
</style>
